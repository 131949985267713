import Vue from "vue";

/*TITLE*/
document.title = "Baltum Homes | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Baltum Homes";
Vue.prototype.$subtitle = "PROMOCIÓN DE 34 VIVIENDAS EN EL PASTEL (BOADILLA DEL MONTE)";

/*INTRO*/
Vue.prototype.$promoter = "Baltum Homes";
Vue.prototype.$introSubtitle = "PROMOCIÓN DE 34 VIVIENDAS EN EL PASTEL (BOADILLA DEL MONTE)";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "0-livingroom--20221124091417.jpeg";
Vue.prototype.$image_kitchen = "0-kitchen--20221124091417.jpeg";
Vue.prototype.$image_bath1 = "0-bathroom1--20221124091417.jpeg";
Vue.prototype.$image_bath2 = "0-bathroom2--20221124091417.jpeg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "0-livingroom--20221124091417.jpeg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "0-kitchen--20221124091417.jpeg",
  },
  {
    src: "1-kitchen--20221124091417.jpeg",
  },
  {
    src: "2-kitchen--20221124091417.jpeg",
  },
  {
    src: "3-kitchen--20221124091417.jpeg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "0-bathroom1--20221124091417.jpeg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "0-bathroom2--20221124091417.jpeg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/BALTUM_HOMES/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://player.vimeo.com/video/760326353";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=boadilla%20del%20monte%20madrid%2028660",
    text: "Boadilla del Monte, Madrid, 28660",
  },
];

Vue.prototype.$showroom = [
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:629340212",
    text: "629340212",
  },
  {
    icon: "mdi-email",
    link: "mailto:ventas@baltum.es",
    text: "ventas@baltum.es",
  },
];
